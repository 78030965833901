<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Active Issuances</p>
      </div>
      <div class="column is-narrow">
        <b-button
          icon-left="plus"
          type="is-info"
          @click="openCreateIssuanceModal"
          >Create Issuance</b-button
        >
      </div>
    </div>
    <div v-if="false" class="columns is-vcentered">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Search..."
            type="search"
            v-model="searchQuery"
            @input="filterItems"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field>
          <div class="columns is-vcentered">
            <div class="column is-narrow bold">Search by:</div>
            <div class="column">
              <b-radio
                class="mt-1 pl-2"
                v-for="(columnData, index) in tableProps.columnData"
                :key="index"
                v-model="searchType"
                name="searchType"
                :native-value="columnData"
                @input="filterItems"
              >
                {{ tableProps.columnTitle[index] }}
              </b-radio>
            </div>
          </div>
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="Go to page:">
          <b-input v-model="tableProps.page" type="number" min="1"></b-input>
        </b-field>
      </div>
    </div>
    <b-input
      class="mt-4"
      placeholder="Search Book by Accession No..."
      type="search"
      v-model="searchQuery"
      @input="filterItems"
    ></b-input>
    <hr />
    <b-table
      :data="filteredIssuances"
      :loading="tableProps.isLoading"
      :striped="tableProps.isStriped"
      :total="tableProps.total"
      :per-page="tableProps.perPage"
      :current-page="parseInt(tableProps.page)"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
    >
      <b-table-column label="Accession No" sortable v-slot="props">
        {{ props.row.printedBook.accessionNo }}
      </b-table-column>

      <b-table-column label="Title" sortable v-slot="props">
        {{ props.row.printedBook.title }}
      </b-table-column>

      <b-table-column label="Author" sortable v-slot="props">
        {{
          Array.isArray(props.row.printedBook.authors)
            ? props.row.printedBook.authors.join(", ")
            : props.row.printedBook.authors | filter
        }}
      </b-table-column>

      <b-table-column label="Issue Date" sortable v-slot="props">
        {{ props.row.issueDate | filter }}
      </b-table-column>

      <b-table-column label="Due Date" sortable v-slot="props">
        {{ props.row.dueDate | filter }}
      </b-table-column>

      <b-table-column label="Issued To" sortable v-slot="props">
        {{ props.row.user.firstName + " " + props.row.user.lastName }}
      </b-table-column>

      <b-table-column sortable label="Member ID" v-slot="props">
        {{ props.row.user.memberId }}
      </b-table-column>

      <b-table-column label="Overdue Days" sortable v-slot="props">
        {{ props.row.dueDate | daysDuration(new Date()) }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" sortable v-slot="props">
        <span
          class="has-text-info pointer-active ml-4"
          @click="openReturnBookModal(props.row)"
        >
          <i class="mdi mdi-clipboard-arrow-left pr-1"></i> Return
        </span>
      </b-table-column>
    </b-table>
    <!-- Modals Start -->
    <b-modal v-model="modals.showReturnBookModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Return Book</p>
        </header>
        <section v-if="itemDetail" class="modal-card-body">
          <div class="columns">
            <div class="column has-text-centered">
              <b-field label="Return Book Date">
                <b-datepicker
                  locale="en-GB"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                  v-model="returnDate"
                  inline
                  :max-date="new Date()"
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="columns">
            <div class="column">
              <b-button label="Cancel" @click="closeReturnBookModal" />
              <b-button
                label="Confirm"
                @click="returnBook(itemDetail.printedBook._id, returnDate)"
                type="is-success"
                class="ml-2"
              />
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
    <b-modal
      v-model="modals.showCreateIssuanceModal"
      has-modal-card
      full-screen
    >
      <div
        class="modal-card"
        style="height: 100% !important; overflow-y: scroll"
      >
        <form @submit.prevent="createIssuance" id="issuanceForm">
          <header class="modal-card-head">
            <p class="modal-card-title">Create Issuance</p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <b-field v-if="users" label="Member">
                  <b-autocomplete
                    v-model="userQuery"
                    :data="filteredUsersArray"
                    placeholder="Enter Member ID or Name "
                    icon="magnify"
                    clearable
                    @select="option => (selected = option)"
                    required
                  >
                    <template #empty>No results found</template>
                  </b-autocomplete>
                </b-field>

                <b-field v-if="books" label="Book">
                  <b-autocomplete
                    v-model="bookQuery"
                    :data="filteredBooksArray"
                    placeholder="Enter Accession No. or Book Name"
                    icon="magnify"
                    clearable
                    @select="option => (selected = option.split('[:::]'))"
                    required
                  >
                    <template #empty>No results found</template>
                  </b-autocomplete>
                </b-field>

                <div class="columns">
                  <div class="column">
                    <b-field label="Book Issue Date">
                      <b-datepicker
                        locale="en-GB"
                        placeholder="Click to select..."
                        icon="calendar-today"
                        trap-focus
                        v-model="createIssuanceData.issueDate"
                        required
                      >
                      </b-datepicker>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Book Due Date">
                      <b-datepicker
                        locale="en-GB"
                        placeholder="Click to select..."
                        icon="calendar-today"
                        trap-focus
                        v-model="createIssuanceData.dueDate"
                        required
                      >
                      </b-datepicker>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-message v-if="selectedUser" type="is-info">
                      <div class="columns is-vcentered">
                        <div class="column">
                          <p class="heading bold">Member Details</p>
                          <p class="text-small mt-2">Name</p>
                          <p>
                            {{
                              selectedUser.firstName +
                                " " +
                                selectedUser.lastName
                            }}
                          </p>
                          <p class="text-small mt-2">Member ID</p>
                          <p>{{ selectedUser.memberId }}</p>
                          <p class="text-small mt-2">Contact</p>
                          <p>{{ selectedUser.contact }}</p>
                          <p class="text-small mt-2">Email</p>
                          <p>{{ selectedUser.email }}</p>
                        </div>
                        <div class="column is-narrow">
                          <img
                            :src="selectedUser.profilePicture"
                            width="120px"
                          />
                        </div>
                      </div>
                    </b-message>
                  </div>
                  <div class="column">
                    <b-message v-if="selectedBook" type="is-success">
                      <div class="columns is-vcentered">
                        <div class="column">
                          <p class="heading bold">Book Details</p>
                          <p class="text-small mt-2">Title</p>
                          <p>
                            {{ selectedBook.title }}
                          </p>
                          <p class="text-small mt-2">Author(s)</p>
                          <p>{{ selectedBook.authors.join(", ") }}</p>
                          <p class="text-small mt-2">Accession No.</p>
                          <p>{{ selectedBook.accessionNo }}</p>
                          <p class="text-small mt-2">ISBN</p>
                          <p>{{ selectedBook.ISBN }}</p>
                        </div>
                        <div class="column is-narrow">
                          <img :src="selectedBook.coverPhoto" width="120px" />
                        </div>
                      </div>
                    </b-message>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <div class="columns">
              <div class="column">
                <b-button label="Cancel" @click="closeCreateIssuanceModal" />
                <button
                  v-if="!modals.isCreateIssuanceLoading"
                  label="Confirm"
                  type="submit"
                  class="ml-2 button is-success"
                  form="issuanceForm"
                >
                  Confirm
                </button>
                <b-button
                  v-else
                  label="Confirm"
                  type="is-success"
                  class="ml-2"
                  loading
                />
              </div>
            </div>
          </footer>
        </form>
      </div>
    </b-modal>
    <!-- Modals End -->
  </div>
</template>

<script>
import { orderBy, filter } from "lodash";
import notificationService from "../../../services/notificationService";

export default {
  name: "Viewer",
  data() {
    return {
      filteredItems: undefined,
      searchQuery: "",
      searchType: "memberId",
      returnDate: new Date(),
      tableProps: {
        columnTitle: [
          "Accession No",
          "Title",
          "Author",
          "Issue Date",
          "Due Date",
          "Issued To",
          "Overdue Days"
        ],
        columnData: [
          "printedBook.accessionNo",
          "printedBook.title",
          "printedBook.authors",
          "issueDate",
          "dueDate",
          "user.memberId",
          "overdueDays"
        ],
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      },
      modals: {
        showReturnBookModal: false,
        showCreateIssuanceModal: false,
        isCreateIssuanceLoading: false,
        viewModalTitles: [
          "Accession No",
          "Title",
          "Author",
          "Issue Date",
          "Due Date",
          "Issued To",
          "Overdue Days"
        ],
        viewModalTitlesData: [
          "accessionNumber",
          "title",
          "author",
          "issueDate",
          "dueDate",
          "memberId",
          "overdueDays"
        ]
      },
      userQuery: "",
      bookQuery: "",
      itemDetail: undefined,
      createIssuanceData: {
        userId: undefined,
        bookId: undefined,
        issueDate: undefined,
        dueDate: undefined
      },
      filteredIssuances: undefined
    };
  },
  async created() {
    this.tableProps.isLoading = true;
    await this.$store.dispatch("getIssuances", "active");
    this.filteredItems = this.items;
    this.tableProps.isLoading = false;
    await this.$store.dispatch("getUsers");
    await this.$store.dispatch("getBooks");
  },
  computed: {
    items() {
      return orderBy(this.$store.state.issuedBooks, ["dateCreated"], ["desc"]);
    },
    users() {
      return orderBy(this.$store.state.users, ["dateCreated"], ["desc"]).filter(
        user => {
          return user.role == "ipri";
        }
      );
    },
    books() {
      return orderBy(this.$store.state.books, ["dateCreated"], ["desc"]);
    },
    filteredUsersArray() {
      if (this.users) {
        const users = this.users.map(
          user => user.memberId + " ::: " + user.firstName + " " + user.lastName
        );
        return users.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.userQuery.toLowerCase()) >= 0
          );
        });
      }
      return [];
    },
    filteredBooksArray() {
      if (this.books) {
        const books = this.books.map(
          book => book.accessionNo + " ::: " + book.title
        );
        return books.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.bookQuery.toLowerCase()) >= 0
          );
        });
      }
      return [];
    },
    selectedBook() {
      if (this.bookQuery) {
        return this.books.filter(book => {
          return book.accessionNo == this.bookQuery.split(":::")[0].trim();
        })[0];
      } else {
        return undefined;
      }
    },
    selectedUser() {
      if (this.userQuery) {
        return this.users.filter(user => {
          return user.memberId == this.userQuery.split(":::")[0].trim();
        })[0];
      } else {
        return undefined;
      }
    }
  },
  watch: {
    items: function(val) {
      this.filteredItems = val;
      this.filterItems();
    }
  },
  methods: {
    closeReturnBookModal() {
      this.modals.showReturnBookModal = false;
    },
    async returnBook(bookId, returnDate) {
      returnDate | filter;
      const returnBookRequest = {
        bookId,
        returnDate
      };
      await this.$store
        .dispatch("returnBook", returnBookRequest)
        .then(async () => {
          await this.$store.dispatch("getIssuances", "active");
        });
      this.tableProps.isLoading = true;
      this.filteredItems = this.items;
      this.tableProps.isLoading = false;
      this.closeReturnBookModal();
    },
    async createIssuance() {
      if (!this.createIssuanceData.issueDate) {
        notificationService.error("Please select an issue date");
        this.modals.isCreateIssuanceLoading = false;
      } else if (!this.createIssuanceData.dueDate) {
        notificationService.error("Please select a due date");
        this.modals.isCreateIssuanceLoading = false;
      } else {
        this.modals.isCreateIssuanceLoading = true;
        const request = {
          userId: this.selectedUser._id,
          bookId: this.selectedBook._id,
          issueDate: this.createIssuanceData.issueDate
            .toISOString()
            .split("T")[0],
          dueDate: this.createIssuanceData.dueDate.toISOString().split("T")[0]
        };

        await this.$store.dispatch("issueBook", request).then(async res => {
          if (res) {
            this.modals.showCreateIssuanceModal = false;
            await this.$store.dispatch("getIssuances", "active");
            this.modals.isCreateIssuanceLoading = false;
          } else {
            this.modals.isCreateIssuanceLoading = false;
          }
        });
      }
    },
    openReturnBookModal(selectedItem) {
      this.itemDetail = selectedItem;
      this.modals.showReturnBookModal = true;
    },
    openCreateIssuanceModal() {
      this.modals.showCreateIssuanceModal = true;
    },
    closeCreateIssuanceModal() {
      this.modals.isCreateIssuanceLoading = false;
      this.modals.showCreateIssuanceModal = false;
      this.createIssuanceData = {
        userId: undefined,
        bookId: undefined,
        issueDate: undefined,
        dueDate: undefined
      };
      this.bookQuery = "";
      this.userQuery = "";
    },
    filterItems() {
      if (this.items) {
        if (this.searchQuery === "") {
          this.filteredIssuances = this.items;
        } else {
          this.filteredIssuances = filter(this.items, item => {
            return item.printedBook["accessionNo"]
              .toString()
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
